.digitGroup {
    display: flex;
    gap: 0.7rem;
}

.digitGroup .otpInput {
    outline: 0 !important;
    user-select: none !important;
    width: 50px;
    height: 50px;
    background-color: #C5DBF5;
    font-weight: bold !important;
    border-radius: 5px;
    border: none;
    line-height: 50px;
    text-align: center;
    font-size: 24px;
    color: black;
    margin: 0;
}

.digitGroup .otpInput:focus {
    border: 2px solid #001E9A !important;
}

.digitGroup .otpInput:active {
    border: 2px solid #001E9A !important;
}

.digitGroup .splitter {
    padding: 5px 0;
    color: rgb(0, 0, 0);
    font-size: 25px;
    margin: 0;
}

.prompt {
    margin-bottom: 20px;
    font-size: 20px;
    color: white;
}

.w-100 {
    width: 100%;
}


.btnGroup {
    text-align: center;
}


/* Media query for mobile devices */
@media (max-width: 480px) {
    .digitGroup {
        gap: 5px !important;
    }

    .digitGroup .otpInput {
        width: 40px !important;
    }

    .digitGroup .splitter {
        font-size: 25px !important;
    }
}